// Default colors

@use '@angular/material' as mat;
@include mat.core();

.mat-toolbar.mat-primary {
    --mat-toolbar-container-background-color: #3f51b5 !important;
    --mat-toolbar-container-text-color: white;
}

.mat-drawer-container {
    background-color: transparent !important;
}

.mat-mdc-button:not(:disabled) {
    color: white !important;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-color: yellow !important;
}

 .mat-mdc-icon-button {
     color: white !important;
 }

$my-theme: mat.define-theme((
        color: (
                theme-type: dark,
                primary: mat.$azure-palette,
                tertiary: mat.$yellow-palette,
        )
));

html {
    @include mat.all-component-themes($my-theme);
}


.sidenav-right {
    width: calc(50vw) !important;
    min-width: 400px;
}


//@include mat.all-component-themes($theme);
//
//// Applying the custom theme to all components
//@include mat-core.all-component-themes($theme);
//
//// Applying the custom theme to all components
//@include mat.all-component-themes($theme);
//
//// Apply theme to all material components
//@include mat.core-theme($theme);

//$dark-theme: mat.m2-define-dark-theme((
//        color: (
//                primary: mat.m2-define-palette(mat.$m2-indigo-palette),
//                accent: mat.m2-define-palette(mat.$m2-purple-palette),
//        ),
//    // Only include `typography` and `density` in the default dark theme.
//        typography: mat.m2-define-typography-config(),
//        density: 0,
//));
//
//
//// Define a light theme
//$light-theme: mat.m2-define-light-theme((
//        color: (
//                primary: mat.m2-define-palette(mat.$m2-indigo-palette),
//                accent: mat.m2-define-palette(mat.$m2-teal-palette),
//        ),
//));

//// Apply the dark theme by default
//@include mat.core-theme($dark-theme);
//@include mat.button-theme($dark-theme);
//
//// Apply the light theme only when the user prefers light themes.
//@media (prefers-color-scheme: light) {
//    // Use the `-color` mixins to only apply color styles without reapplying the same
//    // typography and density styles.
//    @include mat.core-color($light-theme);
//    @include mat.button-color($light-theme);
//}
//
//@include mat.all-component-themes($dark-theme);

.default-theme {



    .theme-based-override-user-menu {
        color: white;
    }

    th {
        color: #ffc107 !important;
        font-weight: bold !important;
    }

    .mat-mdc-tab.mdc-tab--active {
        background-color: #1A2678 !important;
    }
    .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
        color: #ffc107 !important;
    }
    .mat-mdc-tab .mdc-tab-indicator__content--underline {
        border-color: #ffc107 !important;
    }


    .table-top-buttons {
        color: mat.m2-get-color-from-palette(mat.$m2-purple-palette, 500) !important;
    }

    .card {
        background: #1e1e1e;
    }

    .inner-card-bg-with-border {
        //background: linear-gradient(45deg, white, #4baaf5);
        background: linear-gradient(90deg, rgba(236,232,229,1) 0%, rgba(247,232,167,1) 0%, rgba(167,131,71,1) 100%);
        color: black;
    }

    .sc-box {
        clip-path: polygon(0 40.00px,40.00px 0,100% 0,100% calc(100% - 40.00px),calc(100% - 40.00px) 100%,0 100%);
    }

    .bg-card  {
        position: relative;
        //color: #4baaf5;
        //text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
        color: black;
    }

    .bg-card :before {
        content: "";
        position: absolute;
        inset: 0;
        //background: linear-gradient(45deg, white, #4baaf5);
        background: linear-gradient(90deg, rgba(236,232,229,1) 0%, rgba(247,232,167,1) 14%, rgba(167,131,71,1) 28%, rgba(36,55,124,1) 100%);
        //background: __<<ngM2ThemingMigrationEscapedComment0>>__;
        clip-path: polygon(0 40.00px,40.00px 0,100% 0,100% calc(100% - 40.00px),calc(100% - 40.00px) 100%,0 100%,0 40.00px,2px  calc(40.00px + 0.83px),2px calc(100% - 2px),calc(100% - 40.00px - 0.83px) calc(100% - 2px),calc(100% - 2px) calc(100% - 40.00px - 0.83px),calc(100% - 2px) 2px,calc(40.00px + 0.83px) 2px,2px calc(40.00px + 0.83px));
    }

    .bg-card:hover :before {
        content: "";
        position: absolute;
        inset: 0;
        //background: linear-gradient(45deg, white, #4baaf5);
        background: linear-gradient(90deg, #18ffff 0%, rgba(36,55,124,1) 14%, #009688 28%, #ffc107 100%);
        //background: __<<ngM2ThemingMigrationEscapedComment1>>__;
    }

    .bg-card {
        background-color: transparent !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center center !important;
    }

    .bg-bot-events {
        background-image: url("../assets/img/card-background-bot-events.png") !important;
    }
    .bg-bot-mining-loadouts {
        background-image: url("../assets/img/card-background-mining-loadouts.png") !important;
    }
    .bg-bot-resources {
        background-image: url("../assets/img/card-background-bot-resources.png") !important;
    }
    .bg-about-bot {
        background-image: url("../assets/img/card-background-about-bot.png") !important;
    }
    .bg-admin {
        background-image: url("../assets/img/card-background-admin.png") !important;
    }
    .bg-members {
        background-image: url("../assets/img/card-background-members.png") !important;
    }
    .bg-news {
        background-image: url("../assets/img/card-background-news.png") !important;
    }
    .bg-members-points {
        background-image: url("../assets/img/card-background-members-points.png") !important;
    }
    .bg-guides {
        background-image: url("../assets/img/card-background-guides.png") !important;
    }
    .bg-race {
        background-image: url("../assets/img/card-background-race.png") !important;
    }
    .bg-rally-series {
        background-image: url("../assets/img/card-background-rally-series.png") !important;
    }
    .bg-exploration {
        background-image: url("../assets/img/card-background-exploration.png") !important;
    }
    .bg-fleet {
        background-image: url("../assets/img/card-background-fleet.png") !important;
    }
    .bg-contracts {
        background-image: url("../assets/img/card-background-contracts.png") !important;
    }
    .bg-inventory {
        background-image: url("../assets/img/card-background-inventory.png") !important;
    }
    .bg-cargo-sell-planer {
        background-image: url("../assets/img/card-background-cargo-sell-planer.png") !important;
    }

    .ware-house-card-header {
        background-color: transparent;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("../assets/img/WarehouseTransparent.png");
        min-height: 200px;
    }


    .ware-house-private-card-header {
        background-color: transparent;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("../assets/img/PrivateTransparent.png");
        min-height: 200px;
    }

    //.top-menu-item {
    //    width: 200px;
    //    text-align: center;
    //    margin-right: 8px;
    //    //background: black;
    //}
    //.top-menu-item {
    //    background: linear-gradient(90deg, rgba(236,232,229,1) 0%, rgba(247,232,167,1) 0%, rgba(167,131,71,1) 100%);
    //    //clip-path: polygon(0 20.00px,20.00px 0,100% 0,100% calc(100% - 20.00px),calc(100% - 20.00px) 100%,0 100%,0 20.00px,1px  calc(20.00px + 0.41px),1px calc(100% - 1px),calc(100% - 20.00px - 0.41px) calc(100% - 1px),calc(100% - 1px) calc(100% - 20.00px - 0.41px),calc(100% - 1px) 1px,calc(20.00px + 0.41px) 1px,1px calc(20.00px + 0.41px));
    //    clip-path: polygon(0 20.00px,20.00px 0,100% 0,100% calc(100% - 20.00px),calc(100% - 20.00px) 100%,0 100%);
    //}
    //.top-menu-item > div {
    //    width: 200px;
    //    text-align: center;
    //    background: linear-gradient(90deg, rgba(236,232,229,1) 0%, rgba(247,232,167,1) 0%, rgba(167,131,71,1) 100%);
    //}
}

