@use '@angular/material' as mat;

// @use '@angular/material/theming';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
@include mat.all-component-typographies();
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.core();


@import "themes/default-theme";

@import 'assets/css/buttons.css';


.scroll-content {
  //background-image: url("assets/img/BG_1.jpg");
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}


.scroll-content {
  background-image: url("assets/img/BG_1.jpg");
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}