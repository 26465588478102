.button-add, .button-save {
    background-color: #3CA532 !important;
    color: white !important;
}
.button-add mat-icon, .button-refresh mat-icon, .button-delete mat-icon {
    font-weight: bold !important;
}
.button-bottom {
    position: fixed !important;
    right: 16px !important;
    bottom: 16px !important;
}
.button-edit {
    background-color: #0087B9 !important;
    color: white !important;
}
.button-delete {
    background-color: #F01414 !important;
    color: white !important;
}

.mat-mdc-raised-button:disabled {
    background-color: lightgray !important;
}

.button-cancel{
    background-color: #ff5722 !important;
    color: white !important;
}

.button-refresh {
    background-color: #00bcd4 !important;
    color: white !important;
}
