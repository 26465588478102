/* You can add global styles to this file, and also import other style files */

@import '../node_modules/material-design-icons-iconfont/dist/material-design-icons.css';

/*@import "@angular/material/prebuilt-themes/purple-green.css";*/

/* angular-cli file: src/styles.css */
@import '../node_modules/angular-calendar/css/angular-calendar.css';

@font-face {
    font-family: 'Poppins';
    font-weight: 100;
    font-style: normal;
    src: url('assets/css/google/Poppins-Thin.eot');
    src: url('assets/css/google/Poppins-Thin.eot?#iefix') format('embedded-opentype'),
    url('assets/css/google/Poppins-Thin.woff2') format('woff2'),
    url('assets/css/google/Poppins-Thin.woff') format('woff'),
    url('assets/css/google/Poppins-Thin.ttf') format("truetype");
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    font-weight: 200;
    font-style: normal;
    src: url('assets/css/google/Poppins-ExtraLight.eot');
    src: url('assets/css/google/Poppins-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('assets/css/google/Poppins-ExtraLight.woff2') format('woff2'),
    url('assets/css/google/Poppins-ExtraLight.woff') format('woff'),
    url('assets/css/google/Poppins-ExtraLight.ttf') format("truetype");
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    font-weight: 300;
    font-style: normal;
    src: url('assets/css/google/Poppins-Light.eot');
    src: url('assets/css/google/Poppins-Light.eot?#iefix') format('embedded-opentype'),
    url('assets/css/google/Poppins-Light.woff2') format('woff2'),
    url('assets/css/google/Poppins-Light.woff') format('woff'),
    url('assets/css/google/Poppins-Light.ttf') format("truetype");
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    font-weight: 400;
    font-style: normal;
    src: url('assets/css/google/Poppins-Regular.eot');
    src: url('assets/css/google/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
    url('assets/css/google/Poppins-Regular.woff2') format('woff2'),
    url('assets/css/google/Poppins-Regular.woff') format('woff'),
    url('assets/css/google/Poppins-Regular.ttf') format("truetype");
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    font-weight: 500;
    font-style: normal;
    src: url('assets/css/google/Poppins-Medium.eot');
    src: url('assets/css/google/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
    url('assets/css/google/Poppins-Medium.woff2') format('woff2'),
    url('assets/css/google/Poppins-Medium.woff') format('woff'),
    url('assets/css/google/Poppins-Medium.ttf') format("truetype");
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    font-weight: 600;
    font-style: normal;
    src: url('assets/css/google/Poppins-SemiBold.eot');
    src: url('assets/css/google/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('assets/css/google/Poppins-SemiBold.woff2') format('woff2'),
    url('assets/css/google/Poppins-SemiBold.woff') format('woff'),
    url('assets/css/google/Poppins-SemiBold.ttf') format("truetype");
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    font-weight: 700;
    font-style: normal;
    src: url('assets/css/google/Poppins-SemiBold.eot');
    src: url('assets/css/google/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('assets/css/google/Poppins-SemiBold.woff2') format('woff2'),
    url('assets/css/google/Poppins-SemiBold.woff') format('woff'),
    url('assets/css/google/Poppins-SemiBold.ttf') format("truetype");
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    font-weight: 800;
    font-style: normal;
    src: url('assets/css/google/Poppins-ExtraBold.eot');
    src: url('assets/css/google/Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('assets/css/google/Poppins-ExtraBold.woff2') format('woff2'),
    url('assets/css/google/Poppins-ExtraBold.woff') format('woff'),
    url('assets/css/google/Poppins-ExtraBold.ttf') format("truetype");
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    font-weight: 900;
    font-style: normal;
    src: url('assets/css/google/Poppins-Black.eot');
    src: url('assets/css/google/Poppins-Black.eot?#iefix') format('embedded-opentype'),
    url('assets/css/google/Poppins-Black.woff2') format('woff2'),
    url('assets/css/google/Poppins-Black.woff') format('woff'),
    url('assets/css/google/Poppins-Black.ttf') format("truetype");
    font-display: swap;
}

:root {
    --menu-text-color: white;
    --menu-selected-text-color: white;
    --menu-selected-bg-color: #ffc107;
    --menu-selected-shadow-color: rgba(156, 39, 176, .4);

}

html {
    height: 100%;
    margin: 0;
}

body {
    margin: 0;
    /*font-family: Arial;*/
    /*font-family: 'Poppins', sans-serif;*/
    font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
    height: 100%;


}

.app-full-height {
    height: 100%;
}

div.splash {
    bottom: 0px ;
    display: flex ;
    left: 0px ;
    position: fixed ;
    right: 0px ;
    top: 0px ;
}
div.splash--loaded {
    opacity: 0.0 ;
    transition: opacity 500ms ease ;
}
div.splash__message {
    font-size: 26px ;
    line-height: 31px ;
    margin: auto auto auto auto ;
    text-decoration: underline ;
}

/* scroll layout */
.top-bar
{
    max-height: 64px;
    min-height: 64px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
}

.scroll-content
{
    /*overflow: auto;*/
    /* height: 100%;*/
    /*height: calc(100vh - 64px);*/
    /*margin-top: 64px;*/
    /*left: 0px;*/
    /*width: 100vw;*/
    /*height: calc(100vh - 64px);*/
    /*height: 100vh;*/
    /*position: fixed;*/
}


.guide-content-container img {
    /*max-width: ;*/
    max-width: calc(100vw - 320px);
}

@media (max-width: 599px)
{
    .top-bar
    {
        max-height: 56px;
        min-height: 56px;
    }
    .scroll-content
    {
        /*margin-top: 56px;*/
        /*height: calc(100vh - 56px);*/
        /*min-height: -webkit-fill-available;*/
    }
    .side-nav-content
    {
        top:0 !important;
    }
    .guide-content-container img {
        /*max-width: ;*/
        max-width: calc(100vw - 64px);
    }
}



.custom-sidenav-container {
    position: inherit;
    min-height: 100%;
    display: inherit;
    transform: inherit;
}

.side-nav-menu-container input, .side-nav-menu-container label, .side-nav-menu-container mat-icon,
.side-nav-menu-container mat-form-field.mat-mdc-form-field {
    color: white !important;
}

/** Overrides underline color **/
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.side-nav-menu-container .mat-form-field-appearance-legacy .mat-form-field-underline,
.side-nav-menu-container .mat-form-field-appearance-legacy .mat-form-field-ripple,
.side-nav-menu-container .mat-form-field-appearance-legacy.mat-focused
.mat-form-field-underline {
    background-color: white !important;
}

/** Overrides label color **/
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.side-nav-menu-container .mat-form-field-appearance-legacy .mat-form-field-label,
.side-nav-menu-container .mat-form-field-appearance-legacy.mat-focused
.mat-form-field-label {
    color: white;
}

/** Overrides caret & text color **/
.side-nav-menu-container .mat-form-field-appearance-legacy .mat-mdc-input-element {
    caret-color: white !important;
    color: white !important;
}

.shadow-bottom {
    box-shadow: 0 3px 5px -1px rgba(0,0,0,.2),
    0 6px 10px 0 rgba(0,0,0,.14),
    0 1px 18px 0 rgba(0,0,0,.12);
    z-index: 10;
}


.card-button-container {
    /* Add shadows to create the "card" effect */
    margin-bottom: 10px;
    /*max-width: 800px;*/
    margin-left:auto;
    margin-right:auto;
}
.card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, .2), 0px 1px 1px 0px rgba(0, 0, 0, .14), 0px 1px 3px 0px rgba(0, 0, 0, .12);
    transition: 0.3s;
    background-color: #303030;
    margin-bottom: 10px;
    /*max-width: 800px; */
    margin-left:auto;
    margin-right:auto;
    text-align: left;
    border-radius: 4px;
}

/* On mouse-over, add a deeper shadow */
/*.card:hover {*/
/*    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);*/
/*}*/

.padding {
    padding: 16px;
}

.card-row {
    padding: 10px;
    min-height: 56px;
}

.full-width {
    width: 100%;
}

.form-button {

}
.form-button-container
{
    /* max-width: 820px !important; */
    margin-left:auto !important;
    margin-right:auto !important;
    margin-bottom: 20px !important;
}

.content-container-wrapper {
    margin-top: 64px;
    overflow: auto;
    height: calc(100vh - 64px);
}

.sidenav-right {
    position: fixed !important;
    top: 64px !important;
    bottom: 0 !important;
    overflow: auto !important;
}


.content-container-wrapper > *:first-child  {
    min-height: calc(100vh - 256px);
}

.button-login-discord {
    text-decoration: none !important;
    display: inline-block !important;
    vertical-align: middle !important;
    width: 180px !important;
    height: 50px !important;
    border-width: 0 !important;
    background-color: rgb(114 137 218) !important;
    color: #fff !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 1em !important;
}

@media (max-width: 963px) {
    .content-container-wrapper > *:first-child  {
        min-height: calc(100vh - 288px);
    }
    .top-menu-buttons {
        display: none;
    }
}

@media (max-width: 599px) {
    .content-container-wrapper {
        margin-top: 56px;
        height: calc(100vh - 56px);
    }
    .content-container-wrapper > *:first-child  {
        min-height: calc(100vh - 248px);
    }
    .sidenav-right {
        top: 56px !important;
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
mat-list-item {
    position: relative;
}

.sidemenu mat-expansion-panel {
    padding-top: 3px;
    padding-bottom: 3px;
}

/* overrides for slide menu */
.shorten-menu-text {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    min-width: 30px;
    line-height: 32px;
}
mat-panel-title .shorten-menu-text {
    min-width: 20px;
}

app-menu .mat-expansion-panel-body {
    padding: 0;
}
app-menu .mat-mdc-menu-item, app-menu .mat-expansion-panel-header {
    max-height: 32px;
    line-height: 32px;
    padding-left: 8px !important;
}
.slide-menu a.active .mat-mdc-menu-item {
    margin-left: 0 !important;
    padding-left: 8px !important;
}
.slide-inner mat-sidenav-content {
    margin-left: 0 !important;
}
.pinned-inner mat-sidenav-content {
    /*margin-left: 316px !important;*/
}
.left-side-nav {
    box-shadow: 0 16px 38px -12px rgba(0,0,0,.56),
    0 4px 25px 0 rgba(0,0,0,.12),
    0 8px 10px -5px rgba(0,0,0,.2);
}

.slide-inner-container .content-container-no-cls {
    padding-left: 16px;
}

.flex-container {
    display: flex; /* or inline-flex */
}

.side-nav-menu-container:hover .sidenav button.sidenavAccordeon, .side-nav-menu-container:hover .sidenav a {
    text-overflow: ellipsis;
    min-width: 300px;
    max-width: 300px;
    overflow-x: hidden;
    white-space: nowrap;
    -webkit-transition: min-width 0.5s; /* For Safari 3.1 to 6.0 */
    transition: min-width 0.5s;
}


.slide-menu .sidenav button.sidenavAccordeon {
    padding-top: 9px;
    padding-bottom: 9px;
}

.slide-menu:hover .sidenav button.sidenavAccordeon {
    padding-top: 9px;
    padding-bottom: 3px;
}
.slide-menu .sidenav button.sidenavAccordeon1 {
    padding-top: 6px;
    padding-bottom: 6px;
}

.slide-menu:hover .sidenav button.sidenavAccordeon1 {
    padding-top: 6px;
    padding-bottom: 6px;
}


.slide-inner .group-menu > .mdc-button {
    text-overflow: ellipsis;
    max-width: 60px !important;
    width: 60px !important;
    /* min-width: 300px; */
    overflow: hidden;
    white-space: nowrap;
    transition: min-width 0.5s;
}
.slide-inner .sidemenu.sidenav div {
    text-overflow: ellipsis;
    max-width: 60px !important;
    width: 60px !important;
    /* min-width: 300px; */
    overflow: hidden;
    white-space: nowrap;
    transition: min-width 0.5s;
}

.side-nav-inner:hover .group-menu > .mdc-button {
    max-width: initial !important;
    width: initial !important;
}
.side-nav-inner:hover .sidemenu.sidenav div {
    max-width: initial !important;
    width: initial !important;
}

.slide-menu .sidenav button.sidenavAccordeon, .slide-menu .sidenav a, .slide-menu .copy-right {
    /*
    text-overflow: ellipsis;
    max-width: 80px;
    min-width: 80px;
    overflow: hidden;
    white-space: nowrap;
    -webkit-transition: min-width 0.5s;
    transition: min-width 0.5s;
    */
    text-overflow: ellipsis;
    /* max-width: 300px; */
    /* min-width: 300px; */
    overflow: hidden;
    white-space: nowrap;
    transition: min-width 0.5s;
    /* margin: 6px; */
}
.sidenav a {
    text-overflow: ellipsis;
    overflow-x: hidden;
    min-width: 262px;
    /*max-width: 262px;*/
    white-space: nowrap;
}
.slide-menu .sidenav a {
    max-width: 300px;
    min-width: 10px;
}
.slide-menu .open-right {
    display: none;
}
.slide-menu:hover .open-right {
    display: block;
}
.side-nav-menu-container:hover .sidenav a {
    min-width: 262px;
    /*max-width: 262px;*/
}

.pinned-menu .sidenavAccordeon {
    min-width: 200px !important;
    max-width: 200px !important;
}
.pinned-menu:hover .sidenavAccordeon {
    min-width: 200px !important;
    max-width: 200px !important;
}
.top-bar {
    transition: all 330ms ease;
}
.slide-inner .top-bar {
    margin-left: 64px;
}
.pinned-inner .top-bar {
    margin-left: 216px;
}

a.link-clear {
    text-decoration: none !important;
    color: inherit;
}
a.link-clear:visited {
    color: inherit;
}
a.active {
    background-color: #F01414 !important;
    color: white !important;
}


/* tiles */
.tiles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 32px;
    align-items: stretch;
}
.bigger-tiles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 32px;
    align-items: stretch;
}
.big-tiles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 32px;
    align-items: stretch;
}

.tile-card {
    min-width: 100%;
    min-height: 200px;
    max-height: 200px;
    margin-bottom: -4px;
}

.tile-card-user {
    min-width: 100%;
    min-height: 200px;
    max-height: 200px;
    margin-bottom: -4px;
}


.top-search-container {
    font-size: 0.9em;
    margin-top: 10px;
    color: white !important;
}

.full-width-tiles {
    grid-template-columns: 1fr;
}
.full-width-tiles div.tile-container {
    padding-top: 16px;
    display: block;
}

.full-width-tiles app-home-message:first-of-type div.tile-container {
    padding-top: 0px;
    display: block;
}

.full-width-tiles app-home-message:last-of-type div.tile-container {
    padding-bottom: 16px;
    display: block;
}

.top-menu-only-big {
    display: block;
}

app-training-request div.tile-container {
    padding-top: 32px !important;
    display: block;
}

.two-tiles {
    display: grid;
    grid-gap: 32px;
    align-items: stretch;
    grid-template-columns: 1fr 1fr;
}


@media (max-width: 801px) {
    .two-tiles {
        grid-template-columns: 1fr;
    }
}


@media (min-width: 1401px) {
    .tiles {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .bigger-tiles {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .big-tiles {
        grid-template-columns: 1fr 1fr;
    }
    .top-search-container {
        max-width: 600px;
        min-width: 600px;
    }
}

@media (min-width: 1601px) {
    .tiles {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    .bigger-tiles {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .big-tiles {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .top-search-container {
        max-width: 800px;
        min-width: 800px;
    }
}

@media (min-width: 2501px) {
    .tiles {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    .bigger-tiles {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    .big-tiles {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .top-search-container {
        max-width: 800px;
        min-width: 800px;
    }
}

@media (max-width: 1400px) {
    .tiles {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .bigger-tiles {
        grid-template-columns: 1fr 1fr;
    }
    .big-tiles {
        grid-template-columns: 1fr 1fr;
    }
    .top-search-container {
        max-width: 600px;
        min-width: 600px;
    }
}

@media (max-width: 1200px) {
    .tiles {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .bigger-tiles {
        grid-template-columns: 1fr 1fr;
    }
    .big-tiles {
        grid-template-columns: 1fr 1fr;
    }
    .top-search-container {
        max-width: 500px;
        min-width: 500px;
    }

    .top-menu-only-big {
        display: none;
    }
}


@media (max-width: 1000px) {
    .tiles {
        grid-template-columns: 1fr 1fr;
    }
    .bigger-tiles {
        grid-template-columns: 1fr 1fr;
    }
    .big-tiles {
        grid-template-columns: 1fr;
    }
    .top-search-container {
        max-width: 400px;
        min-width: 400px;
    }
}


@media (max-width: 800px) {
    .tiles {
        grid-template-columns: 1fr;
    }
    .bigger-tiles {
        grid-template-columns: 1fr;
    }
    .big-tiles {
        grid-template-columns: 1fr;
    }
    .top-search-container {
        max-width: 400px;
        min-width: 400px;
    }

    breadcrumb, .app-name {
        /*display: none;*/
    }
}


@media (max-width: 600px) {
    .tiles {
        grid-template-columns: 1fr;
    }
    .bigger-tiles {
        grid-template-columns: 1fr;
    }
    .big-tiles {
        grid-template-columns: 1fr;
    }
    .top-search-container {
        max-width: 200px;
        min-width: 200px;
    }

    breadcrumb, .app-name {
        /*display: none;*/
    }

    .top-menu-only-big {
        display: none;
    }
}

@media (max-width: 400px) {
    .tiles {
        grid-template-columns: 1fr;
    }
    .bigger-tiles {
        grid-template-columns: 1fr;
    }
    .big-tiles {
        grid-template-columns: 1fr;
    }
    .top-search-container {
        max-width: 150px;
        min-width: 150px;
    }

    breadcrumb, .app-name {
        /*display: none;*/
    }

    .top-menu-only-big {
        display: none;
    }
}

.menuCardContainer i {
    margin-top: 20px;
}

.paging-toolbar-row {
    background-color: var(--mat-paginator-container-background-color);
    padding: 0 !important;
}
.paging-spacer {
    flex: 1 1 auto;
}
th mat-checkbox {
    padding-right: 16px;
}


/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
mat-dialog-container {
    padding: 0 !important;
}

@media screen and (max-width: 1200px) {
    div.table-scroll-container table {
        border: 0;
    }

    div.table-scroll-container table caption {
        font-size: 1.3em;
    }

    div.table-scroll-container table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    div.table-scroll-container table tr {
        border-bottom: 3px solid #ddd !important;
        display: block;
        margin-bottom: .625em;
    }

    div.table-scroll-container table td {
        border-bottom: 1px solid #ddd !important;
        display: block;
        font-size: .8em;
        text-align: left;
        height: 32px;
        line-height: 32px;
    }

    div.table-scroll-container table td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
        text-align: left;
        padding-right: 8px;
        max-width: 50px;
        min-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    div.table-scroll-container table td:last-child {
        border-bottom: 0;
    }

    div.table-scroll-container tr.mat-mdc-row {
        height: inherit !important;
    }

    /*
    td mat-checkbox {
        margin-left: -24px !important;
    }
    */
    .mat-column--fb_column_expand .mat-focus-indicator{
        margin-top: -8px !important;
    }

    .fb-expanded-detail-row > td.mat-column-expandedDetail {
        display: inline-table !important;
    }
}

code.dc-code {
    background-color: #00c853 !important;
    display: inline-block;
    max-width: 780px;
    border: 4px solid #00c853 !important;
    border-radius: 4px;
}

@media screen and (max-width: 600px) {
    table td::before {
        max-width: 100px;
        min-width: 100px;
    }
    code.dc-code {
        background-color: #00c853 !important;
        display: inline-block;
        max-width: 400px;
    }
}

.menu-section {
    max-width: 260px;
    min-width: 260px;
    padding-left: 10px;
    padding-right: 10px;
}

.menu-section mat-checkbox span {
    white-space: nowrap;
    /*
    max-width: 220px;
    min-width: 220px;
    */
}


angular-file-uploader .container {
    width: auto !important;
}

/*.input-cell {*/
/*    padding-right: 4px !important;*/
/*}*/

/* fixed tabs */
.mat-mdc-tab-group {
   /*max-height: calc(100vh - 64px);*/
    display: inline;
}
/*@media screen and (max-width: 600px) {*/
/*    .mat-mdc-tab-group {*/
/*        max-height: calc(100vh - 56px);*/
/*    }*/
/*}*/


/*override table */
tr.hidden-row > td.mat-mdc-cell {
    border: 0px !important;
}

tr.mat-mdc-footer-row:hover, tr.mat-mdc-row:hover {
    /*background-color: #FFFFDC !important;*/
    color: #000000 !important;
}
tr.mat-mdc-footer-row:hover *, tr.mat-mdc-row:hover * {

    color: #FF7A00 !important;
}
tr.mat-mdc-footer-row:hover .mat-mdc-text-field-wrapper *, tr.mat-mdc-row:hover .mat-mdc-text-field-wrapper * {
    color: #FF7A00 !important;
}
tr.mat-mdc-row:hover .mdc-text-field *, tr.mat-mdc-row:hover .mat-mdc-text-field-wrapper {
    /*background-color: #FFFFDC !important;*/
    color: #FF7A00 !important;
}



.no-padding {
    padding: 0 !important;
}
.no-left-padding {
    padding-left: 0 !important;
}


.alert-danger {
    color: orange;
    font-weight: bold;
}



.top-search-container mat-icon{
    top: 10px;
    position: sticky;
}


.copy-right {
    font-size: 0.8em;
}


.table-filter .field-group > div {
    grid-template-columns: 1fr !important;
}

.search {
    color: white !important;
}


.dialog-comment .field-group > div {
    grid-template-columns: 1fr !important;
}


.force-multiline-input-container {
    /*max-width: 500px;*/
}

.force-multiline-inputs, .force-multiline-input-container {
    grid-template-columns: 1fr !important;
}

.force-multiline-input-container .ql-container {
    height: 500px !important;
}

/*
quill-editor p {
    float: left;
    background: #ddf;
    padding: 13px;
    margin: 0 13px 13px 0;
}
quill-editor br {
    clear: left;
    background: #000;
    padding: 13px;
    margin: 0 13px 13px 0;
}
*/

.ql-container.ngx-quill-view-html {
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.blog-card {
    max-width: 1200px;
}

@media screen and (max-width: 1200px) {
    .blog-card {
        max-width: calc(100vw - 48px);
    }
}

.colspan-2 {
    grid-column: 2;
}


/* iframe load */
.loader {
    min-height: 100%;
}

.loader,
.loader:before,
.loader:after {
    background: red;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
}

.loader {
    color: red;
    text-indent: -9999em;
    margin: 88px auto;
    position: relative;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.loader:before,
.loader:after {
    position: absolute;
    top: 0;
    content: '';
}

.loader:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.loader:after {
    left: 1.5em;
}

@-webkit-keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

@keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

.loader-container {
    position: fixed;
    left: 0;
    right: 0px;
    bottom: 0px;
    top: 40%;
    display: none;
}

.field-info-tip {
    font-family: Helvetica, Arial, sans-serif;
    background: red !important;
    color: white !important;
    white-space: pre !important;
    font-size: 1em !important;
    max-width: 100% !important;
    min-width: 200px !important;
}

.mat-drawer-content {
    overflow: hidden !important;
}


.style {
    width: 100px;
    max-width: 100px;
    display: table-cell;
    background: transparent !important;
}


/*.table-buttons-bottom {*/
/*    position: absolute;*/
/*    padding-left: 10px;*/
/*}*/

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-radio-label-content {
    padding-right: 8px !important;
}

.ngx-timepicker {
    height: inherit !important;
}


#overlay-saving {
    bottom: 0px;
    display: none;
    left: 0px;
    position: fixed;
    right: 0px;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
    margin: auto auto auto auto;
}

.overlay-saving-_message {
    font-size: 26px;
    line-height: 31px;
    margin: auto auto auto auto;
    color: white;
}


td .field-group > div.input-cell {
    grid-template-columns: 1fr !important;
}


.mat-mdc-tab-body-content {
    /*height: calc(100vh - 115px) !important;*/
    overflow: hidden !important;
}

/* discord messages */
.dc-block span {
    background-color: #17171a !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
    border-radius: 5px;
}
code {
    border-radius: 5px;
}
.message-details ul li p {
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
}

pre > code {
    white-space: normal !important;
}
.message-details a {
    text-decoration: none;
    color: rgb(0, 168, 252) !important;
}
.message-details a:hover {
    text-decoration: underline !important;
}

.discord-embed markdown > p, markdown > p > a {
    white-space: normal;
    max-width: 428px;
    word-break: break-word;
}

.top-menu-buttons {
    margin-right: 32px;
}

.top-bar button.active {
    color: #ffc107 !important;
}

.angular-editor-toolbar button {
    color: black !important;
}

.ql-snow .ql-stroke {
    stroke: white !important;
}
.ql-sc-tool-tip {
    text-decoration: none !important;
    color: rgb(0, 168, 252) !important;
}

.NgxEditor__MenuBar {
    background-color: #1e1e1e !important;
}
.NgxEditor__Popup, .NgxEditor {
    background-color: #1e1e1e !important;
    color: white !important;
}

.NgxEditor__Dropdown:hover, .NgxEditor__MenuItem:hover {
    background-color: #3e3e3e !important;
}
.NgxEditor__Dropdown .NgxEditor__Dropdown--DropdownMenu {
    background-color: #3e3e3e !important;
}
.NgxEditor__Dropdown .NgxEditor__Dropdown--Item:hover {
    background-color: #2e2e2e !important;
}


/* workaround for angular-datetime-picker */
.mat-mdc-tab {
    background-color: #3f51b5 !important;
}

.mat-datepicker-content {
    box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
    background-color: #000000de;
    color: #fff
}

.mat-calendar-body-selected {
    background-color: #007f51;
    color: #fff;
}

.mat-calendar-body-cell-content {
    border: none !important;
}

/*.table-wrapper p-overlay {*/
/*    position: fixed !important;*/
/*}*/

#viewer_container canvas {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
}

.provider .mdc-label {
    font-weight: bold !important;
    color:  rgb(0, 168, 252) !important;
}

.fy-vehicle-tile {
    background-color: transparent !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
}

.grid-tile-left > div {
    justify-content: start !important;
}

.group-caption {
    text-align: left;
    padding-left: 8px;
    color: #ffc107;
    font-weight: normal;
    font-size: 1.2em;
    margin-bottom: 4px;
}

pre {
    font-family: Poppins, sans-serif;
}

.f-left {
    float: left;
}

.f-right {
    float: right;
}

.f-clear {
    clear: both;
}

.m-right-16 {
    margin-right: 16px;
}

.expand-column {
    width: 4em !important;
}

.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}


.table-wrapper table tr th:last-child, .table-wrapper table tr td:last-child {
    width: 100% !important;
    max-width:  100% !important;
}

.tooltip {
    position: absolute;
    background: darkslategrey;
    border: 0 solid transparent;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    font-size: 12px;
    z-index: 1000;
}

.tooltip-table {
    border-collapse: collapse;
    width: 200px;
}

.tooltip-table th, .tooltip-table td {
    border: 0 solid transparent;
    padding: 4px;
    text-align: left;
    color: white;
}